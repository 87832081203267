import React from 'react';
import Typography from '@mui/material/Typography';

import { useQuery } from 'react-query';
import cms from 'utils/cms';
import custom_palettes from 'theme/custom_palettes';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';
import ButtonTab from 'components/ButtonTab';
import VerticalTabs from 'components/VerticalTab';
import useWidth from 'utils/useWidth';

import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import ReactMarkdown from 'react-markdown';

import { isEmpty } from 'lodash';
import YouTube from 'react-youtube';
// =============================================================================
const HEADERS = [
  'Overview',
  'Speakers',
  'Downloads',
  'Livestream',
  'About Singapore'
];

// =============================================================================
export default function MMAEvent() {
  const width = useWidth();
  const [value, setValue] = React.useState(0);

  const {
    isLoading,
    isError,
    data: query
  } = useQuery('mma', () =>
    cms.get('/api/mma?populate=deep').then((res) => res.data)
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const content = query?.data.attributes;

  const {
    headerTitle,
    image,
    overview,
    speakers,
    downloads,
    livestream,
    aboutSingapore
  } = content;

  return (
    <div>
      <div style={{ height: 24 }} />
      <Container
        style={
          width === 'mobile' ? undefined : { marginBottom: 48, marginTop: 48 }
        }
      >
        <Typography
          variant={width === 'mobile' ? 'body_large_strong' : 'h2'}
          color='blue.900'
        >
          {headerTitle}
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.blue[50] }} />

      <div style={{ background: custom_palettes.blue[50] }}>
        <div style={{ height: width === 'mobile' ? 40 : 80 }} />
        <Container
          style={
            width === 'mobile'
              ? undefined
              : {
                  display: 'flex',
                  flexDirection: 'row'
                }
          }
        >
          {width === 'mobile' ? (
            <ButtonTab headers={HEADERS} onClick={handleChange} value={value} />
          ) : (
            <VerticalTabs
              headers={HEADERS}
              value={value}
              onChange={handleChange}
              tabStyle={{
                textAlign: 'right',
                whiteSpace: 'nowrap'
              }}
            />
          )}
          <div style={{ height: 32 }} />
          <div
            style={
              width === 'mobile' ? undefined : { margin: '0 64px', flex: 1 }
            }
          >
            {value == 0 &&
              overview.map(
                (
                  { title, description }: { title: any; description: any },
                  index: number
                ) => (
                  <React.Fragment key={`overview-${index}`}>
                    <Divider sx={{ backgroundColor: 'blue.300' }} />
                    <div style={{ height: 32 }} />
                    {index == 0 && (
                      <>
                        <div
                          style={{
                            width: '100%',
                            height: '300px',
                            overflow: 'hidden',
                            marginBottom: '10px',
                            ...(!isEmpty(image?.data?.attributes?.url) && {
                              backgroundImage: `url(${
                                import.meta.env.VITE_CMS_URL +
                                image?.data?.attributes?.url
                              })`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center'
                            })
                          }}
                        />
                        <div style={{ height: 16 }} />
                      </>
                    )}
                    <Accordion
                      defaultExpanded
                      disableGutters
                      elevation={0}
                      square
                      sx={{ backgroundColor: 'blue.50' }}
                    >
                      <Typography variant='body_large_strong' color='blue.900'>
                        {title}
                      </Typography>
                      <Typography variant='body_regular' color='blue.900'>
                        <ReactMarkdown className='markdown'>
                          {description}
                        </ReactMarkdown>
                      </Typography>
                    </Accordion>
                    <div style={{ height: 32 }} />
                  </React.Fragment>
                )
              )}

            {value == 1 &&
              speakers.map(
                (
                  {
                    name,
                    description,
                    image
                  }: { name: any; description: any; image: any },
                  index: number
                ) => (
                  <React.Fragment key={`speaker-${index}`}>
                    <Divider sx={{ backgroundColor: 'blue.300' }} />
                    <div style={{ height: 32 }} />
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '140px',
                        height: '140px',
                        overflow: 'hidden',
                        marginBottom: '10px',
                        ...(!isEmpty(image?.data?.attributes?.url) && {
                          backgroundImage: `url(${
                            import.meta.env.VITE_CMS_URL +
                            image?.data?.attributes?.url
                          })`,
                          backgroundSize: 'cover'
                        })
                      }}
                    />
                    <Accordion
                      defaultExpanded
                      disableGutters
                      elevation={0}
                      square
                      sx={{ backgroundColor: 'blue.50' }}
                    >
                      <Typography variant='body_large_strong' color='blue.900'>
                        {name}
                      </Typography>
                      <Typography variant='body_regular' color='blue.900'>
                        <ReactMarkdown className='markdown'>
                          {description}
                        </ReactMarkdown>
                      </Typography>
                    </Accordion>
                    <div style={{ height: 32 }} />
                  </React.Fragment>
                )
              )}

            {value == 2 &&
              downloads.map(
                (
                  { title, links }: { title: any; links: any },
                  index: number
                ) => (
                  <React.Fragment key={`download-${index}`}>
                    <Divider sx={{ backgroundColor: 'blue.300' }} />
                    <div style={{ height: 32 }} />
                    <Accordion
                      defaultExpanded
                      disableGutters
                      elevation={0}
                      square
                      sx={{ backgroundColor: 'blue.50' }}
                    >
                      <Typography variant='body_large_strong' color='blue.900'>
                        {title}
                      </Typography>
                      <div style={{ height: 20 }} />
                      {links.map(
                        (
                          { text, link }: { text: any; link: any },
                          linkIndex: number
                        ) => (
                          <div key={`download-link-${index}-${linkIndex}`}>
                            <a
                              href={link}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{ textDecoration: 'none' }}
                            >
                              <Typography
                                variant='body_regular'
                                color='blue.900'
                              >
                                {text}
                              </Typography>
                            </a>
                          </div>
                        )
                      )}
                    </Accordion>
                    <div style={{ height: 32 }} />
                  </React.Fragment>
                )
              )}

            {value == 3 && (
              <React.Fragment>
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {livestream.lectureTimingsTitle}
                  </Typography>
                  <div style={{ height: 20 }} />
                  {livestream.lecture.map(
                    ({ text }: { text: any }, index: number) => (
                      <div key={`lecture-${index}`}>
                        <ReactMarkdown className='markdown'>
                          {text}
                        </ReactMarkdown>
                      </div>
                    )
                  )}
                </Accordion>
                <div style={{ height: 32 }} />
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {livestream.audienceQnaTitle}
                  </Typography>
                  <div style={{ height: 20 }} />
                  <a
                    href={livestream.qNaLink.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant='body_regular' color='blue.900'>
                      {livestream.qNaLink.text}
                    </Typography>
                  </a>
                </Accordion>
                <div style={{ height: 32 }} />
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {livestream.livestreamTitle}
                  </Typography>
                  <div style={{ height: 20 }} />
                  <YouTube
                    videoId={livestream.livestreamVideoId}
                    opts={{
                      width: '100%',
                      height: 400
                    }}
                  />
                </Accordion>
                <div style={{ height: 32 }} />
              </React.Fragment>
            )}

            {value == 4 && (
              <React.Fragment>
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {aboutSingapore.gettingAroundTitle}
                  </Typography>
                  <div style={{ height: 20 }} />
                  <ReactMarkdown className='markdown'>
                    {aboutSingapore.gettingAroundDescription.text}
                  </ReactMarkdown>
                </Accordion>
                <div style={{ height: 32 }} />
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {aboutSingapore.suggestedAttractionsTitle}
                  </Typography>
                  <div style={{ height: 20 }} />
                  {aboutSingapore.suggestedAttractionsLinks.map(
                    (
                      { text, link }: { text: any; link: any },
                      index: number
                    ) => (
                      <div key={`attraction-link-${index}`}>
                        <Typography variant='body_regular' color='blue.900'>
                          {text} -{' '}
                          <a
                            href={link}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            Link
                          </a>
                        </Typography>
                      </div>
                    )
                  )}
                </Accordion>
                <div style={{ height: 32 }} />
                <Divider sx={{ backgroundColor: 'blue.300' }} />
                <div style={{ height: 32 }} />
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  square
                  sx={{ backgroundColor: 'blue.50' }}
                >
                  <Typography variant='body_large_strong' color='blue.900'>
                    {aboutSingapore.diveOperatorsTitle}
                  </Typography>
                  <div style={{ height: 10 }} />
                  {aboutSingapore.diveOperators.map(
                    (
                      {
                        title,
                        description,
                        contactDetails
                      }: { title: any; description: any; contactDetails: any },
                      index: number
                    ) => (
                      <div key={`dive-operator-${index}`}>
                        <div style={{ height: 10 }} />
                        <ReactMarkdown className='markdown no-margin'>
                          {title}
                        </ReactMarkdown>
                        <ReactMarkdown className='markdown no-margin'>
                          {description}
                        </ReactMarkdown>
                        <ReactMarkdown className='markdown no-margin'>
                          {contactDetails}
                        </ReactMarkdown>
                      </div>
                    )
                  )}
                </Accordion>
                <div style={{ height: 32 }} />
              </React.Fragment>
            )}

            <Divider sx={{ backgroundColor: 'blue.300' }} />
          </div>
        </Container>

        <div style={{ height: 104 }} />
      </div>
    </div>
  );

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }
}
